import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { currentSectionIndex } from "../../redux/actions/fullpage";

import { setVhUnit, changeOverlayColor, handleKeyNav } from "../../helpers";

import "../../styles/pages/lessonsaustralia/index.scss";

import StartLayout from "../../components/pages/portfolio/StartLayout";
import GoalLayout from "../../components/pages/portfolio/GoalLayout";
import CoreFeaturesLayout from "../../components/pages/portfolio/CoreFeaturesLayout";
import WorkLayout from "../../components/pages/portfolio/WorkLayout";
import MobileUiLayout from '../../components/pages/portfolio/MobileUiLayout'
import FeedbackLayout from '../../components/pages/portfolio/FeedbackLayout'
import Overlay from "../../components/overlay/Overlay";
import ContactUs from "../../components/contact-us";
import Layout from "../../components/Layout";
import Menu from "../../components/menu";

// goal image

import displaySmPng from '../../../static/images/portfolio/monitor-sm.png'
import displaySmWebp from '../../../static/images/portfolio/monitor-sm.webp'
import displayLgPng from '../../../static/images/portfolio/monitor-lg.png'
import displayLgWebp from '../../../static/images/portfolio/monitor-lg.webp'

// goal video

import videoMp4 from '../../../static/videos/lessonsaustralia/video.mp4'

// view images

import Site1SmJpg from "../../../static/images/portfolio/lessonsaustralia/view/view-first-small.jpg";
import Site1LgJpg from "../../../static/images/portfolio/lessonsaustralia/view/view-first-large.jpg";
import Site1SmWebp from "../../../static/images/portfolio/lessonsaustralia/view/view-first-small.webp";
import Site1LgWebp from "../../../static/images/portfolio/lessonsaustralia/view/view-first-large.webp";

import Site2SmJpg from "../../../static/images/portfolio/lessonsaustralia/view/view-second-small.jpg";
import Site2LgJpg from "../../../static/images/portfolio/lessonsaustralia/view/view-second-large.jpg";
import Site2SmWebp from "../../../static/images/portfolio/lessonsaustralia/view/view-second-small.webp";
import Site2LgWebp from "../../../static/images/portfolio/lessonsaustralia/view/view-second-large.webp";

// work images

import work1LgJpg from "../../../static/images/portfolio/lessonsaustralia/work/work-1-lg.jpg"
import work1SmJpg from "../../../static/images/portfolio/lessonsaustralia/work/work-1-sm.jpg"
import work1LgWebp from "../../../static/images/portfolio/lessonsaustralia/work/work-1-lg.webp"
import work1SmWebp from "../../../static/images/portfolio/lessonsaustralia/work/work-1-sm.webp"

import work2LgJpg from "../../../static/images/portfolio/lessonsaustralia/work/work-2-lg.jpg"
import work2SmJpg from "../../../static/images/portfolio/lessonsaustralia/work/work-2-sm.jpg"
import work2LgWebp from "../../../static/images/portfolio/lessonsaustralia/work/work-2-lg.webp"
import work2SmWebp from "../../../static/images/portfolio/lessonsaustralia/work/work-2-sm.webp"

import work3LgJpg from "../../../static/images/portfolio/lessonsaustralia/work/work-3-lg.jpg"
import work3SmJpg from "../../../static/images/portfolio/lessonsaustralia/work/work-3-sm.jpg"
import work3LgWebp from "../../../static/images/portfolio/lessonsaustralia/work/work-3-lg.webp"
import work3SmWebp from "../../../static/images/portfolio/lessonsaustralia/work/work-3-sm.webp"

// mobile ui images

import Ui1LgPng from '../../../static/images/portfolio/lessonsaustralia/mobile-ui/ui-1-lg.png'
import Ui1LgWebp from '../../../static/images/portfolio/lessonsaustralia/mobile-ui/ui-1-lg.webp'

import Ui2LgPng from '../../../static/images/portfolio/lessonsaustralia/mobile-ui/ui-2-lg.png'
import Ui2LgWebp from '../../../static/images/portfolio/lessonsaustralia/mobile-ui/ui-2-lg.webp'

import Ui3LgPng from '../../../static/images/portfolio/lessonsaustralia/mobile-ui/ui-3-lg.png'
import Ui3LgWebp from '../../../static/images/portfolio/lessonsaustralia/mobile-ui/ui-3-lg.webp'

import Ui4LgPng from '../../../static/images/portfolio/lessonsaustralia/mobile-ui/ui-4-lg.png'
import Ui4LgWebp from '../../../static/images/portfolio/lessonsaustralia/mobile-ui/ui-4-lg.webp'

import Ui5LgPng from '../../../static/images/portfolio/lessonsaustralia/mobile-ui/ui-5-lg.png'
import Ui5LgWebp from '../../../static/images/portfolio/lessonsaustralia/mobile-ui/ui-5-lg.webp'

// feedback images

import fb1Jpg from '../../../static/images/portfolio/lessonsaustralia/feedback/feedback1.jpg'
import fb1Webp from '../../../static/images/portfolio/lessonsaustralia/feedback/feedback1.webp'

import fb2Jpg from '../../../static/images/portfolio/lessonsaustralia/feedback/feedback2.jpg'
import fb2Webp from '../../../static/images/portfolio/lessonsaustralia/feedback/feedback2.webp'

export default () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(currentSectionIndex(0));
  }, []);

  const onOrientationChange = () => {
    setTimeout(() => setVhUnit(), 500);
  };

  useEffect(() => {
    setVhUnit();

    window.addEventListener("orientationchange", onOrientationChange);

    return () =>
      window.removeEventListener("orientationchange", onOrientationChange);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const isMobile = window.innerWidth < 992;

    const mobileClass = [
      'goal__media',
      'features-gallery',
      'is-white-image-one',
      'is-white-image-two',
      'is-white-image-three',
      'carousel__slider-tray',
      'feedback__carousel'
    ];

    const desktopClass = [
      'features-gallery',
      'is-white-image-two',
      'carousel__slider-tray',
    ];

    changeOverlayColor(isMobile ? mobileClass : desktopClass);
  };

  /**
   * this function for navigation keyboard
   */
  const handleKeyUp = React.useCallback((event) => {
    const navigationLink = {
      prevSite: "/portfolio/bodyshops/",
      nextSite: "/portfolio/negina/",
    };

    handleKeyNav(event.key, navigationLink);
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);

    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [handleKeyUp]);

  return (
    <Layout>
      <Helmet>
        <title>Zimalab Portfolio | LessonsAustralia</title>
        <meta name="description" content="Zimalab upgraded a portal which allows students to search for qualified teachers nearby according to their studying preferences"/>
        <meta name="Keywords" content="edtech, education software, teacher software, student software, directory software, symfony developer, map integration, map-based search, google maps api, google maps api integration, online education software"/>
      </Helmet>

      <main className="lessonsaustralia-page">
        <StartLayout
          title="Lessons"
          titleTwoPart="Australia"
          subTitle="Teacher search portal"
          subTitleLast='EDUCATION'
        />

        <GoalLayout
          video={ videoMp4 }
          goalSmJpg={ displaySmPng }
          goalLgJpg={ displayLgPng }
          goalSmWebp={ displaySmWebp }
          goalLgWebp={ displayLgWebp }
          typeImage="png"
        >
          Upgrade a portal for easy search for
          <div className="goal__description-last--line">
            teachers and tutors nearby
          </div>
        </GoalLayout>

        <CoreFeaturesLayout
          items={[
            "Location- and subject-based search allows users to find best teachers nearby",
            "Advanced filters that save time for students having different studying preferences ",
            "Rich teacher profile with embeddable media",
            "Online, Studio, and At Your Home lessons",
            "Both Schools and Individual Teachers for Group, Shared, or Individual Private Lessons",
            "Integration with Google Maps to display the teacher location"
          ]}
          timeline='Timeline:'
          timelineData='2020 - now'
          link='https://lessonsaustralia.com.au/'
          const coreFeaturesGallery = {{
            Site1SmJpg,
            Site1LgJpg,
            Site1SmWebp,
            Site1LgWebp,

            Site2SmJpg,
            Site2LgJpg,
            Site2SmWebp,
            Site2LgWebp,
          }}
          typeImage='jpg'
        />

        <WorkLayout
          title="Search"
          subTitle="Location-based search"
          listItems={[
            "Teachers set up the address of their studio or the desired travel radius in their profile",
            "Students can search for teachers near their location",
            "All the nearby teachers are displayed on the map and can be sorted by distance"
          ]}
          imageLgSecondary={ work1LgJpg }
          imageSmSecondary={ work1SmJpg }
          imageLgPrimary={ work1LgWebp }
          imageSmPrimary={ work1SmWebp }
          alt="image"
          isWhiteImageWorkOne
        />

        <WorkLayout
          title="Filters"
          subTitle="Advanced filters"
          listItems={[
            "Students can choose whether to look for schools or individual teachers",
            "The filters include lesson types and students qualification",
            "The results can be narrowed by teacher gender, language and background checks"
          ]}
          imageLgSecondary={ work2LgJpg }
          imageSmSecondary={ work2SmJpg }
          imageLgPrimary={ work2LgWebp }
          imageSmPrimary={ work2SmWebp }
          alt="image"
          isReversed
          isWhiteImageWorkTwo
        />

        <WorkLayout
          title="Profiles"
          subTitle="Teacher profiles"
          listItems={[
            "Teachers can embed YouTube and Vimeo videos to showcase their skills",
            "Testimonials from students and links to social media are displayed in the profile",
            "Special offers allow teachers to promote their services"
          ]}
          imageLgSecondary={ work3LgJpg }
          imageSmSecondary={ work3SmJpg }
          imageLgPrimary={ work3LgWebp }
          imageSmPrimary={ work3SmWebp }
          alt="image"
          isWhiteImageWorkThree
        />

        <MobileUiLayout
          items={[
            {
              imageLgWebp: Ui1LgWebp,
              imageLgPng: Ui1LgPng
            },
            {
              imageLgWebp: Ui2LgWebp,
              imageLgPng: Ui2LgPng
            },
            {
              imageLgWebp: Ui3LgWebp,
              imageLgPng: Ui3LgPng
            },
            {
              imageLgWebp: Ui4LgWebp,
              imageLgPng: Ui4LgPng
            },
            {
              imageLgWebp: Ui5LgWebp,
              imageLgPng: Ui5LgPng
            }
          ]}
        />

        <FeedbackLayout
          reviewImagesSm={[
            {
              imageWebp: fb1Webp,
              imageJpg: fb1Jpg
            },
            {
              imageWebp: fb2Webp,
              imageJpg: fb2Jpg
            }
          ]}
          reviewImagesLg={[
            {
              imageWebp: fb1Webp,
              imageJpg: fb1Jpg
            },
            {
              imageWebp: fb2Webp,
              imageJpg: fb2Jpg
            }
          ]}
        />

        <ContactUs
          hasNavigation
          prevProject={"/portfolio/bodyshops/"}
          nextProject={"/portfolio/negina/"}
        />

        <Overlay />
        <Menu />
      </main>
    </Layout>
  );
};
